.user-items-list[data-space-below-section-title-value="101"] .list-section-title {
	display: none !important;
}

@media screen and (min-width: 768px) {
	.user-items-list[data-space-below-section-title-value="101"] .desktop-arrows {
		display: none !important;
	}

	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-carousel__gutter {
		pointer-events: none;
		cursor: auto!important;
		overflow: unset;
	}

	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-carousel__slides, .user-items-list[data-space-below-section-title-value="101"] .user-items-list-simple {
		position: relative;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr;
	}

	.user-items-list[data-space-below-section-title-value="101"] .list-item {
		grid-row-start: auto;
		grid-row-end: auto;
		grid-column-start: 1;
		transform: none!important;
		transition: background-color 0.5s linear, opacity 0.2s linear !important;
		pointer-events: auto;
		cursor: pointer;
	}

	.user-items-list[data-space-below-section-title-value="101"] .list-item > [class*="media"] {
		position: absolute;
		left: calc(50% + var(--grid-gap, 50px) / 2);
		top: 0;
		height: 100%;
		width: calc(50% - var(--grid-gap, 50px) / 2) !important;
		margin-bottom: 0!important;
		margin-top: 0!important;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.5s linear;
	}

	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-simple .list-item-media {
		width: calc(50% - var(--sqs-site-gutter) - var(--grid-gap, 50px) / 2) !important;
	}

	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-simple[data-media-alignment="left"] .list-item-media {
		left: var(--sqs-site-gutter) !important;
	}

	.user-items-list[data-space-below-section-title-value="101"] .list-item [class*="media-inner"] {
		padding-bottom: 0!important;
		height: 100% !important;
	}

	.user-items-list[data-space-below-section-title-value="101"] [data-media-alignment="left"] .list-item > [class*="media"] {
		left: 0;
	}

	.user-items-list[data-space-below-section-title-value="101"] [data-media-alignment="left"] .list-item {
		grid-column-start: 2;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .list-item[data-is-card-enabled="true"] {
		background-color: transparent;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-simple .list-item.active[data-is-card-enabled="true"] {
		background-color: var(--list-section-simple-card-color);
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-carousel .list-item.active[data-is-card-enabled="true"] {
		background-color: var(--list-section-carousel-card-color);
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .list-item-content__button-wrapper {
		height: 0;
		overflow: hidden;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .list-item-content__button-wrapper .list-item-content__button-container {
		opacity: 0!important;
		transition: 0.5s opacity linear !important;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .list-item.active .list-item-content__button-wrapper {
		height: 100%;
		overflow: unset;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .list-item.active .list-item-content__button-wrapper .list-item-content__button-container {
		opacity: 1 !important;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .list-item.active > [class*="media"], .user-items-list[data-space-below-section-title-value="101"] .list-item:first-child > [class*="media"] {
		opacity: 1;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .list-item:not(.active):has([data-aspect-ratio="original"])>[class*=media] {
		opacity: 0;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-carousel__slides-revealer {
		overflow: unset !important;
	}

	.user-items-list[data-space-below-section-title-value="101"] .list-item [class*=media-inner][data-aspect-ratio="original"] {
		position: absolute;
		inset: 0;
	}
	
	.user-items-list[data-space-below-section-title-value="101"] .list-item [class*=media-inner][data-aspect-ratio="original"] img {
		min-height: unset;
		height: 100%;
		width: 100%;
		position: absolute;
		inset: 0;
		object-fit: contain;
	}
}

@media screen and (max-width: 767px) {
	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-carousel__slides, .user-items-list[data-space-below-section-title-value="101"] .user-items-list-simple {
		grid-template-columns: 1fr;
	}
	.user-items-list[data-space-below-section-title-value="101"] .list-item {
		padding: 0!important;
		background: none;
	}

	.user-items-list[data-space-below-section-title-value="101"] .list-item > [class*="media"] {
		width: 100% !important;
	}
        .user-items-list[data-space-below-section-title-value="101"] .user-items-list-carousel .desktop-arrows {
		display: none!important;
	}
	.user-items-list[data-space-below-section-title-value="101"] .user-items-list-carousel .mobile-arrows {
		display: flex!important;
	}
}
